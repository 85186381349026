@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'right_grotesktight_regular';
  src: url('/src/assets/fonts/rightgrotesk-tightregular-webfont.woff2') format('woff2'),
       url('/src/assets/fonts/rightgrotesk-tightregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "right_grotesktight_medium";
  src: url("/src/assets/fonts/rightgrotesk-widedark-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-tightmedium-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskwide_dark";
  src: url("/src/assets/fonts/rightgrotesk-widedark-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-widedark-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskbold";
  src: url("/src/assets/fonts/rightgrotesk-bold-webfont.woff2") format("woff2"),
    url("/src/assets/fonts/rightgrotesk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskregular";
  src: url("/src/assets/fonts/rightgrotesk-regular-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_grotesktext";
  src: url("/src/assets/fonts/rightgrotesk-text-webfont.woff2") format("woff2"),
    url("/src/assets/fonts/rightgrotesk-text-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_grotesktext_bold";
  src: url("/src/assets/fonts/rightgrotesk-textbold-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-textbold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskwide_regular";
  src: url("/src/assets/fonts/rightgrotesk-wideregular-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-wideregular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskwide_medium";
  src: url("/src/assets/fonts/rightgrotesk-widemedium-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-widemedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "right_groteskwide_regular";
  src: url("/src/assets/fonts/rightgrotesk-wideregular-webfont.woff2")
      format("woff2"),
    url("/src/assets/fonts/rightgrotesk-wideregular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

.h1_hero {
  font-family: 'right_grotesktight_regular';
  text-transform: uppercase;
  font-size: 20vw;
  line-height: 75%;

}

/* @media (min-width: 640px) {
  .h1_hero{
    font-size: 25vh;
  }

  } */

h1,
.h1 {
  font-family: 'right_grotesktight_regular';
  line-height: 75%;
}

.h1__lg {
  font-size: 18vw;
}



h2,
.h2 {
  font-family: "right_groteskwide_dark";
}

h3,
.h3 {
  font-family: "right_grotesktight_medium";
}

h4,
.h4 {
  font-family: "right_grotesktext_bold";
  line-height: 120%;
}

h5,
.h5 {
  font-family: "right_grotesktext";
  line-height: 112.78%;
}

h6,
.h6 {
  font-family: "right_groteskbold";
  line-height: 112.78%;
  text-transform: uppercase;
}

p,
.p {
  font-family: "right_grotesktext";
}

.pbold {
  font-family: "right_grotesktext_bold";
}

button,
.button {
  font-family: "right_grotesktext";
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
}

.oval{
  border-radius:130px/80px;
}

.navLink {
  font-family: "right_groteskwide_regular";
  letter-spacing: 0.1em;
}

body {
  overflow-x: hidden;
  font-family: 'right_groteskregular';
  background: rgb(138,215,233);
  background: radial-gradient(50% 50% at 50% 50%, #DDEFBB 0%, #FFEEEE 47.92%, rgba(255, 238, 238, 0) 100%);
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

}



header {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);

}

html {
  overflow-x: hidden
}

.h-90screen {
  min-height: 85vh;
}


.gradientGreen {
  background-image: radial-gradient(70.12% 70.12% at 50% 50%, #DEDCED 0%, #DCEFBB 52.08%, rgba(255, 255, 255, 0) 100%);
}

.gradientGreen:hover {
  background-image: radial-gradient(70.12% 70.12% at 50% 50%, #DEDCED 39.06%, #E4FFBC 99.99%, rgba(255, 255, 255, 0) 100%);
  transition-property: background-image;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.gradientPink{
  background-image: radial-gradient(82.16% 82.16% at 50% 50%, #EFEFBB 0%, #FFEEEE 55.21%, rgba(255, 255, 255, 0) 100%);
}

.gradientPink:hover {
  background-image: radial-gradient(82.16% 82.16% at 50% 50%, #FFFFD9 41.15%, #FFE6E6 86.98%, rgba(255, 255, 255, 0) 100%);
  transition-property: background-image;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}


.fontXL{
  font-size: 6rem;
}




/* nav {
  font-family: "right_groteskwide_regular";
  font-size: 1rem;
  line-height: 112%;
} */

